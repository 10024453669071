import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from './Navbar'
import { FaLinkedin, FaTwitter, FaFacebookF } from 'react-icons/fa';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { SvgElement, icontypesEnum } from "../asset/svgElement";

function Contact() {

  const [open, setOpen] = useState(1);

  const handleOpen = (value: any) => {
    setOpen(open === value ? 0 : value);
  };


  return (
    <div>
      <Navbar />

      <section className="grid lg:grid-cols-2 grid-cols-1 md:gap-2 mb-20 mt-20 lg:ml-4 sm:mx-4">

        <div className="md:mx-20 rounded-lg  md:mt-20 mt-10 mx-4 " >
          <h2 className=" font-bold mb-6 flex justify-center md:justify-start text-5xl" >
            Talk to our Finance expert
          </h2>

          <p className="flex justify-center ">
            Have questions about our loan services? Need help with an existing loan? Get in touch with us today! Our team is here to answer all your questions and provide personalized loan solutions tailored to meet your needs. Contact us today and let us help you unlock your financial potential!
          </p>


          <div className="mt-5">
            <img className="rounded-t-lg" src="images/contact.png" alt="" />
          </div>

          <div className="grid lg:grid-cols-2 grid-cols-1 mt-10 md:gap-2">
            <span className="mb-10">
              <h2 className="font-bold mb-3">Head Office</h2>
              <p className="flex justify-center ">
                <span className="mr-2">
                  <SvgElement type={icontypesEnum.LOCATION} />
                </span>
                <span>
                  19/21 Balogun Street
                  Off Akinremi Street
                  Anifowose, Ikeja, Lagos
                </span>
              </p>

              <p className="flex  mt-4 ">
                <span className="mr-4">
                  <SvgElement type={icontypesEnum.CALL} />
                </span>

                <span>
                  0813 452 4557
                </span>

              </p>
            </span>

            <span>
              <h2 className="font-bold mb-3">Ado Ekiti</h2>
              <p className="flex justify-center ">
                <span className="mr-2">
                  <SvgElement type={icontypesEnum.LOCATION} />
                </span>
                <span>
                  Suite 6, Block A
                  Phenrose Filling Station
                  Ajilosun , Ado Ekiti, Ekiti State
                </span>
              </p>

              <p className="flex  mt-4 ">
                <span className="mr-4">
                  <SvgElement type={icontypesEnum.CALL} />
                </span>

                <span>
                  0813 452 4557
                </span>

              </p>
            </span>

          </div>
        </div>


        <div className="md:mx-20  p-4  md:mt-20 mx-1" >

          <form className="mt-4 shadow-md rounded-2xl p-7">

            <div className="grid lg:grid-cols-2 grid-cols-1 gap-3">
              <div className="mb-6">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                <input type="text" id="first name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="First Name" required />
              </div>

              <div className="mb-6">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                <input type="text" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Last Name" required />
              </div>
            </div>


            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
              <input type="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Email" required />
            </div>

            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
              <input type="number" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Phone Number" required />
            </div>

            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Message</label>
              <textarea id="message" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 " placeholder="message"></textarea>
            </div>

            <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center ">Send</button>
          </form>


        </div>
      </section>



<footer id="footer" style={{ backgroundColor: '#0A0F18' }} className="py-16 px-8">
  <div className="container px-4">
    <div className="flex flex-wrap">
      <div className="w-full  md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
        <div className="mx-3 mb-8">

          <div className='flex justify-center md:justify-start'>
            <h2 className="text-3xl font-bold leading-snug text-[#3d4fca] mb-5 ">
              <span className=' bg-opacity-70'>
                <img src="images/logo2.png" className=" w-28 bg-white" alt="" />
              </span>
            </h2>
          </div>

          <p className="text-gray-300 mb-2 flex justify-center md:justify-start">
            Copyright 2023 Skypay
          </p>

          <p className="text-gray-300 mb-5 flex justify-center md:justify-start">
            All right reserved
          </p>

          <ul className="social-icons flex md:justify-start justify-center">
            <li className="mr-4">
              <a
                href="javascript:void(0)"
                className="
              flex
              justify-center
              items-center
              w-8
              h-8
              bg-white
              rounded-full
              text-sm text-gray-700
              duration-300
              hover:text-white hover:bg-blue-400
            "
              >
                <FaFacebookF />
              </a>
            </li>
            <li className="mr-4">
              <a
                href="javascript:void(0)"
                className="
              flex
              justify-center
              items-center
              w-8
              h-8
              bg-white
              rounded-full
              text-sm text-gray-700
              duration-300
              hover:text-white hover:bg-red-500
            "
              >
                <FaTwitter />
              </a>
            </li>
            <li className="mr-4">
              <a
                href="javascript:void(0)"
                className="
              flex
              justify-center
              items-center
              w-8
              h-8
              bg-white
              rounded-full
              text-sm text-gray-700
              duration-300
              hover:text-white hover:bg-indigo-600
            "
              >
                <FaLinkedin />
              </a>
            </li>
          </ul>


        </div>
      </div>

      <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
        <div className="mx-3 mb-8">
          <h3 className="font-bold text-xl text-white mb-5">Menu</h3>
          <ul>
            <li>
              <a
                href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
              >
                Features
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
              >
                Services
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
              >
                About us
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
        <div className="mx-3 mb-8">
          <h3 className="font-bold text-xl text-white mb-5">Company</h3>
          <ul>
            <li>
              <a
                href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
              >
                Management
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
              >
                Our story
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
              >
                Partnership
              </a>
            </li>
          </ul>
        </div>
      </div>


      <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
        <div className="mx-3 mb-8">
          <h3 className="font-bold text-xl text-white mb-5">Support</h3>
          <ul>
            <li>
              <a
                href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
              >
                Support Center
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
              >
                Transaction
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
              >
                Privacy Policy
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>


      <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
        <div className="mx-3 mb-8">
          <h3 className="font-bold text-xl text-white mb-5">Get notified by Skypay</h3>
          <form>
            <label  className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <SvgElement type={icontypesEnum.EMAIL} />
              </div>
              <input type="search" id="search" className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="EMAIL" required />
                <button type="submit" className="text-white absolute right-2.5 bottom-2.5 bg-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2 "><SvgElement type={icontypesEnum.SEND} /></button>
            </div>
          </form>


        </div>
      </div>
    </div>
  </div>
</footer>
    </div>
  )
}
export default Contact;
