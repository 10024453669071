import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from './Navbar'
import { FaLinkedin, FaTwitter, FaFacebookF } from 'react-icons/fa';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { SvgElement, icontypesEnum } from "../asset/svgElement";

function About() {

  const [open, setOpen] = useState(1);

  const handleOpen = (value: any) => {
    setOpen(open === value ? 0 : value);
  };


  return (
    <div>
      <Navbar />




      <section id="team" className="text-center px-3 mt-32">
        <div className="container ">
          <div className="text-center">
            <h2 style={{ color: '#080705' }} className="text-4xl font-bold tracking-wide">
              Our Mission is to Empower Financial Freedom<br /> Through Accessible Loan Solutions
            </h2>

            <h2 style={{ color: '#080705' }} className="mt-4 tracking-wide md:mb-10">
              We believe that everyone should have access to the resources they need to achieve their goals. We strive to provide<br /> accessible loan solutions that empower individuals, businesses, and organizations to unlock their financial potential. Our<br /> mission is to make loan accessibility amazing for everyone involved.
            </h2>
          </div>

        </div>
      </section>

      <section className="grid lg:grid-cols-2 grid-cols-1 md:gap-8 lg:ml-4 sm:mx-4">

        <div className="rounded-lg mx-4" >
          <SvgElement type={icontypesEnum.STORY} />
        </div>

        <div className="md:mx-20 rounded-lg mb-32 md:mt-20 mx-2 " >
          <h2 className=" font-bold mb-6 flex justify-center md:justify-start" style={{ fontSize: '32px' }}>
            our story
          </h2>

          <p className="flex justify-center">
            Skypay Credit Limited was incorporated in 2022 as a lending company to provide microcredit to gainfully employed individuals and SME loans to businesses. Within the short period of our entrance into the Nigerian lending environment, we have acquired a good reputation for supporting individuals and small and medium companies to meet up with their critical financial needs. Our services are available to employees in private and public sectors. All loans are insured with reputable insurance companies.<br/><br/>

            With our head office in Lagos, we are able to service our clients using modern technology that enable us to disburse loans fast to our customers within 4 hours of successful completion of their loan applications. The company is committed to render ethical and responsible lending services with competitive interest rates.
          </p>

        </div>
      </section>


      <section className="lg:ml-4 mx-2 mt-4 lg:mt-32 mb-32">
        <p  className="text-3xl font-bold mb-6 ml-4">What we stand for</p>

        <p style={{fontWeight:'bold' }} className=" md:text-4xl text-xl  lg:w-4/5 mx-3">At our company, we stand for providing accessible<br/> loan solutions that empower<br/> people to achieve their financial goals.</p>

        <div className="">

          <div className="mb-10 grid lg:grid-cols-2 grid-cols-1 gap-8">
            <span style={{fontSize: '18px' }} className="flex lg:justify-items-start justify-items-center gap-4 mt-6 lg:px-4 py-4 rounded-lg">
              <span><SvgElement type={icontypesEnum.ONE} /></span>
              <span><span className="block pb-1" style={{fontWeight:'bold'}}>Equal access to loan solutions</span> <br/> <span style={{fontSize:'16px'}} className="mt-4">We strive to make loan accessibility available to everyone, regardless of background or financial situation.</span></span>
            </span>

            <span style={{fontSize: '18px' }} className="flex justify-items-start gap-4 mt-6 px-4 py-4 rounded-lg">
              <span><SvgElement type={icontypesEnum.TWO} /></span>
              <span><span className="block pb-1" style={{fontWeight:'bold'}}>Financial literacy</span> <br/> <span style={{fontSize:'16px'}} className="mt-4">We believe in the importance of educating people on the importance of budgeting and smart money management.</span></span>
            </span>

            <span style={{fontSize: '18px' }} className="flex justify-items-start gap-4 mt-6 px-4 py-4 rounded-lg">
              <span><SvgElement type={icontypesEnum.THREE} /></span>
              <span><span className="block pb-1" style={{fontWeight:'bold'}}>Responsible lending</span> <br/> <span style={{fontSize:'16px'}} className="mt-4">We strive to provide transparent loan solutions that are tailored to the needs of our customers.</span></span>
            </span>

            <span style={{fontSize: '18px' }} className="flex justify-items-start gap-4 mt-6 px-4 py-4 rounded-lg">
              <span><SvgElement type={icontypesEnum.FOUR} /></span>
              <span><span className="block pb-1" style={{fontWeight:'bold'}}>Quality customer service</span> <br/> <span style={{fontSize:'16px'}} className="mt-6">We strive to provide excellent customer service and support to ensure our customers have the best possible experience.</span></span>
            </span>
          </div>
        </div>

      </section>










      <footer id="footer" style={{ backgroundColor: '#0A0F18' }} className="py-16 px-8">
        <div className="container px-4">
          <div className="flex flex-wrap">
            <div className="w-full  md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">

                <div className='flex justify-center md:justify-start'>
                  <h2 className="text-3xl font-bold leading-snug text-[#3d4fca] mb-5 ">
                    <span className=' bg-opacity-70'>
                      <img src="images/logo2.png" className=" w-28 bg-white" alt="" />
                    </span>
                  </h2>
                </div>

                <p className="text-gray-300 mb-2 flex justify-center md:justify-start">
                  Copyright 2023 Skypay
                </p>

                <p className="text-gray-300 mb-5 flex justify-center md:justify-start">
                  All right reserved
                </p>

                <ul className="social-icons flex md:justify-start justify-center">
                  <li className="mr-4">
                    <a
                      href="javascript:void(0)"
                      className="
                    flex
                    justify-center
                    items-center
                    w-8
                    h-8
                    bg-white
                    rounded-full
                    text-sm text-gray-700
                    duration-300
                    hover:text-white hover:bg-blue-400
                  "
                    >
                      <FaFacebookF />
                    </a>
                  </li>
                  <li className="mr-4">
                    <a
                      href="javascript:void(0)"
                      className="
                    flex
                    justify-center
                    items-center
                    w-8
                    h-8
                    bg-white
                    rounded-full
                    text-sm text-gray-700
                    duration-300
                    hover:text-white hover:bg-red-500
                  "
                    >
                      <FaTwitter />
                    </a>
                  </li>
                  <li className="mr-4">
                    <a
                      href="javascript:void(0)"
                      className="
                    flex
                    justify-center
                    items-center
                    w-8
                    h-8
                    bg-white
                    rounded-full
                    text-sm text-gray-700
                    duration-300
                    hover:text-white hover:bg-indigo-600
                  "
                    >
                      <FaLinkedin />
                    </a>
                  </li>
                </ul>


              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">
                <h3 className="font-bold text-xl text-white mb-5">Menu</h3>
                <ul>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Features
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Services
                    </a>
                  </li>

                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      About us
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">
                <h3 className="font-bold text-xl text-white mb-5">Company</h3>
                <ul>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Management
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Our story
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Partnership
                    </a>
                  </li>
                </ul>
              </div>
            </div>


            <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">
                <h3 className="font-bold text-xl text-white mb-5">Support</h3>
                <ul>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Support Center
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Transaction
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Privacy Policy
                    </a>
                  </li>

                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>


            <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">
                <h3 className="font-bold text-xl text-white mb-5">Get notified by Skypay</h3>
                <form>
                  <label  className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <SvgElement type={icontypesEnum.EMAIL} />
                    </div>
                    <input type="search" id="search" className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="EMAIL" required />
                      <button type="submit" className="text-white absolute right-2.5 bottom-2.5 bg-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2 "><SvgElement type={icontypesEnum.SEND} /></button>
                  </div>
                </form>


              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
export default About;
