import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';



function Navbar() {

  const [isOpen, setIsOpen] = useState(false);
  const [isLog, setIslog] = useState(false);


  return (
    <div>
      <nav className="bg-white px-5 sm:px-4 py-2.5 fixed w-full z-20 top-0 left-0 shadow-md">
        <div className="flex justify-between  mx-auto">
          <div className=" ">
            <NavLink to='/'>
              <div className="">
                <img src="images/logo2.png" className=" w-28 ml-4" alt="" />
              </div>
            </NavLink>
          </div>

          {/* <button data-collapse-toggle="navbar-dropdown" type="button" className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200  bg-blue-100" aria-controls="navbar-dropdown" aria-expanded="false">
            <span className="sr-only">Open main menu</span>
            <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
          </button> */}



          <div className="flex md:order-2">

            <span className="hidden md:block">
              {/* <NavLink to='/login'>
                <button
                  type="button"
                  className="text-[#335ef7] bg-white border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-4  focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-3 "
                >
                  Log In
                </button>
              </NavLink> */}

              <NavLink to="https://skypaycredit.evolvecredit.io/p/skypay-individual-loan-(private-sector)">
                <button
                  type="button"
                  className="text-white bg-[#335ef7] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg mt-2 text-sm px-5 py-2.5 text-center mr-3 md:mr-3 "
                >
                  Get Started
                </button></NavLink>
            </span>





            <button
              onClick={() => setIsOpen(!isOpen)}
              data-collapse-toggle="navbar-sticky"
              type="button"
              className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
              aria-controls="navbar-sticky"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>

          <div
            className="hidden justify-between items-center w-full md:flex md:w-auto md:order-1"
            id="navbar-sticky"
          >
            <div className="flex flex-col p-4 mt-4 bg-white md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 bg-opacity-50">



              <span className="group relative inline-block">
                <button className="inline-flex items-center rounded font-semibold">Loans <span className="mt-1 ml-2 ">^</span></button>
                <ul className="absolute hidden pt-1 text-gray-700 group-hover:block">
                  <NavLink
                    to="/private-sector"
                    className="block py-2 pr-4 pl-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                    style={({ isActive }) =>
                      isActive ? { color: '#335ef7' } : { color: '#374151' }
                    }
                  >
                    <li className=" block w-80 rounded-t bg-white py-2 px-4 hover:bg-blue-100">Skypay Individual Laon(Private sector)</li>
                  </NavLink>

                  <NavLink
                    to="/public-sector"
                    className="block py-2 pr-4 pl-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                    style={({ isActive }) =>
                      isActive ? { color: '#335ef7' } : { color: '#374151' }
                    }
                  >
                    <li className=" block w-80 rounded-t bg-white py-2 px-4 hover:bg-blue-100">Skypay Individual Loan(Public Sector)</li>
                  </NavLink>

                  <NavLink
                    to="/smes"
                    className="block py-2 pr-4 pl-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                    style={({ isActive }) =>
                      isActive ? { color: '#335ef7' } : { color: '#374151' }
                    }
                  >
                    <li className=" block rounded-b bg-white py-2 px-4 hover:bg-blue-100">SMEs Loans</li>
                  </NavLink>

                  <NavLink
                    to="/payday-loan"
                    className="block py-2 pr-4 pl-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                    style={({ isActive }) =>
                      isActive ? { color: '#335ef7' } : { color: '#374151' }
                    }
                  >
                    <li className=" block rounded-b bg-white py-2 px-4 hover:bg-blue-100">PayDay Loans</li>
                  </NavLink>
                </ul>
              </span>




              <NavLink
                to="/about"
                className="block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:p-0 "
                aria-current="page"
                style={({ isActive }) =>
                  isActive ? { color: '#335ef7' } : { color: '#374151' }
                }
              >
                About us
              </NavLink>


              <NavLink
                to="/contact"
                className="block py-2 pr-4 pl-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                style={({ isActive }) =>
                  isActive ? { color: '#335ef7' } : { color: '#374151' }
                }
              >
                Contact
              </NavLink>
              <NavLink
                to="/loan-calculator"
                className="block py-2 pr-4 pl-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                style={({ isActive }) =>
                  isActive ? { color: '#335ef7' } : { color: '#374151' }
                }
              >
                Loan Calculator
              </NavLink>




              {/* <NavLink
                to="/hostels"
                className="block py-2 pr-4 pl-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                style={({ isActive }) =>
                  isActive ? { color: '#335ef7' } : { color: '#374151' }
                }
              >
                Hostels
              </NavLink> */}

              {/* <NavLink
                to="/sidebar"
                className="block py-2 pr-4 pl-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                style={({ isActive }) =>
                  isActive ? { color: '#335ef7' } : { color: '#374151' }
                }
              >
                SideBar
              </NavLink> */}

            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden bg-white w-full mt-3" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">


                <NavLink
                  to="/private-sector"
                  className="block rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                  style={({ isActive }) =>
                    isActive ? { color: '#335ef7' } : { color: '#374151' }
                  }
                >
                  <li className=" block rounded-t bg-white py-2 px-4 hover:bg-blue-100">Skypay Individual Laon(Private sector)</li>
                </NavLink>

                <NavLink
                  to="/public-sector"
                  className="block rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                  style={({ isActive }) =>
                    isActive ? { color: '#335ef7' } : { color: '#374151' }
                  }
                >
                  <li className=" block rounded-t bg-white py-2 px-4 hover:bg-blue-100">Skypay Individual Laon(Public sector)</li>
                </NavLink>

                <NavLink
                  to="/smes"
                  className="block rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                  style={({ isActive }) =>
                    isActive ? { color: '#335ef7' } : { color: '#374151' }
                  }
                >
                  <li className=" block rounded-b bg-white py-2 px-4 hover:bg-blue-100">SMEs Loans</li>
                </NavLink>

                <NavLink
                  to="/payday-loan"
                  className="block rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                  style={({ isActive }) =>
                    isActive ? { color: '#335ef7' } : { color: '#374151' }
                  }
                >
                  <li className=" block rounded-b bg-white py-2 px-4 hover:bg-blue-100">PayDay Loans</li>
                </NavLink>


                <NavLink
                  to="/loan-calculator"
                  className="block py-2 pr-4 pl-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                  style={({ isActive }) =>
                    isActive ? { color: '#335ef7' } : { color: '#374151' }
                  }
                >
                  Loan Calculator
                </NavLink>


                <NavLink
                  to="/about"
                  className="block py-2 pr-4 pl-3 text-white rounded md:bg-transparent md:p-0 "
                  aria-current="page"
                  style={({ isActive }) =>
                    isActive ? { color: '#335ef7' } : { color: '#374151' }
                  }
                >
                  About us
                </NavLink>


                <NavLink
                  to="/contact"
                  className="block py-2 pr-4 pl-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                  style={({ isActive }) =>
                    isActive ? { color: '#335ef7' } : { color: '#374151' }
                  }
                >
                  Contact
                </NavLink>


                <NavLink to="https://skypaycredit.evolvecredit.io/p/skypay-individual-loan-(private-sector)" className="">
                  <button
                    type="button"
                    className="text-white bg-[#335ef7] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg mt-2 text-sm px-5 py-2.5 text-center mr-3 md:mr-3 "
                  >
                    Get Started
                  </button></NavLink>
              </div>
            </div>
          )}
        </Transition>

      </nav>


    </div>
  );
}

export default Navbar;
