import React, { Fragment, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Navbar from './Navbar'
import { FaLinkedin, FaTwitter, FaFacebookF } from 'react-icons/fa';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { SvgElement, icontypesEnum } from "../asset/svgElement";

function LoanCal() {

  const [loanType, setLoantype] = useState<any>('');
  const [amount, setAmount] = useState<any>('');

  const [minPrice, setMinPrice] = useState<any>('');
  const [maxPrice, setMaxPrice] = useState<any>('');
  const [months, setMonths] = useState<any>(0);
  const [totalRepayment, setTotalRepayment] = useState<any>(0);
  const [errorMessage, setErrorMessage] = useState<any>('');

  const loanCalc = React.useCallback(
    (value: any) => {

      setLoantype(value)

      if (value === 'private sector') {
        setMinPrice(20000)
        setMaxPrice(2000000)
      } else if (value === 'public sector') {
        setMinPrice(20000)
        setMaxPrice(1000000)
      } else if (value === 'smes') {
        setMinPrice(50000)
        setMaxPrice(5000000)
      } else if (value === 'payday') {
        setMinPrice(10000)
        setMaxPrice(500000)
      }
    },
    [loanType, minPrice, maxPrice, months]
  );


  const summary = React.useCallback(
    () => {
      if (loanType === 'private sector') {
        if (parseInt(amount, 10) >= 20000 && parseInt(amount, 10) <= 2000000) {
          let total = ((amount * 0.05 * months) + parseInt(amount, 10))
          setTotalRepayment(total)
          setErrorMessage('')
        } else {
          setErrorMessage('Amount must be in the range 20,000 to 2,000,000')
        }
      } else if (loanType === 'public sector') {
        if (parseInt(amount, 10) >= 20000 && parseInt(amount, 10) <= 1000000) {
          let total = ((amount * 0.05 * months) + parseInt(amount, 10))
          setTotalRepayment(total)
          setErrorMessage('')
        } else {
          setErrorMessage('Amount must be in the range 20,000 to 1,000,000')
        }
      } else if (loanType === 'smes') {
        if (parseInt(amount, 10) >= 50000 && parseInt(amount, 10) <= 5000000) {
          let total = ((amount * 0.10 * months) + parseInt(amount, 10))
          setTotalRepayment(total)
          setErrorMessage('')
        } else {
          setErrorMessage('Amount must be in the range 50,000 to 5,000,000')
        }
      } else if (loanType === 'payday') {
        if (parseInt(amount, 10) >= 10000 && parseInt(amount, 10) <= 500000) {
          let total = ((amount * 0.05 * months) + parseInt(amount, 10))
          setTotalRepayment(total)
          setErrorMessage('')
        } else {
          setErrorMessage('Amount must be in the range 10,000 to 500,000')
        }
      }else{
        setErrorMessage('Forms not filled correctly')
      }
    },
    [loanType, amount, months, totalRepayment, errorMessage]
  );

  //   React.useEffect(() => {


  // }, [loanType]);



  return (
    <div>
      <Navbar />
      <section id="team" className="py-24 mt-8 text-center px-3">
        <div className="container ">
          <div className="text-center">
            <h2 style={{ color: '#080705' }} className="text-4xl font-bold tracking-wide">
              Loan Repayment Calculator
            </h2>

            <h2 style={{ color: '#080705' }} className="mt-4 tracking-wide mb-3">
              This loan is for business owners and small and medium enterprises. The essence of the loan is to make<br /> meet urgent financial needs of businesses to enable their operations run without itch.
            </h2>
          </div>


        </div>
      </section>
      {errorMessage === '' ?
      ''
      :
      <div className="flex justify-center text-red-500 bg-red-200 py-4 lg:mx-72">
        <div><SvgElement type={icontypesEnum.DANGERLOGO} /></div>  <div className="pt-3 text-center">{errorMessage}</div>
      </div>
    }
      

      <section className=" lg:mx-10 p-3 m-2 lg:px-16 lg:py-8 lg:m-8 border-grey shadow-lg rounded-lg border-2">

        <form>
          <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 ">
            <div className="mb-6">
              <label htmlFor="countries" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Loan Type</label>
              <select id="countries" onChange={(e: React.ChangeEvent<HTMLSelectElement>) => loanCalc(e.target.value)} name="loan-type" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option selected>Loan Type</option>
                <option value="private sector">Private Sector Loan</option>
                <option value="public sector">Public Sector Loan</option>
                <option value="smes">SMEs Loan</option>
                <option value="payday">PayDay Loan</option>
              </select>
            </div>
            <div className="mb-6">
              <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter Loan Amount</label>
              <input type="number" name="amount" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAmount(e.target.value)} min={minPrice} max={maxPrice} id="password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
              <label htmlFor="password" className="block mb-2 text-sm font-medium text-red-500 ">{minPrice} - {maxPrice}</label>
            </div>

            <div className="mb-6">
              <label htmlFor="countries" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Payment Duration</label>
              <select id="countries" onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setMonths(e.target.value)} name="months" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option selected value='0'> Select Month</option>
                {/* {loanType === 'private sector'?<option value="1">1 month</option> : (loanType ==='public sector') ?<option value="1">1 month</option> :(loanType ==='sme') ? <option value="1">1 month</option> :(loanType ==='payday') ?<option value="1">1 month</option> :'' } */}
                {(loanType === 'private sector' || loanType === 'public sector' || loanType === 'smes' || loanType === 'payday') ? <option value="1">1 month</option> : ''}
                {(loanType === 'private sector' || loanType === 'public sector' || loanType === 'smes') ? <option value="2">2 month</option> : ''}
                {(loanType === 'private sector' || loanType === 'public sector' || loanType === 'smes') ? <option value="3">3 month</option> : ''}
                {(loanType === 'private sector' || loanType === 'public sector') ? <option value="4">4 month</option> : ''}
                {(loanType === 'private sector' || loanType === 'public sector') ? <option value="5">5 month</option> : ''}
                {(loanType === 'private sector' || loanType === 'public sector') ? <option value="6">6 month</option> : ''}
              </select>
            </div>



          </div>
          <div className="  py-8 my-8 border-grey bg-blue-100 rounded-lg border-2">
            {totalRepayment === 0 ? '' : 
            <div>
             <div className=" flex justify-center font-bold text-center mb-4">Your total repayment for {months} month(s) is{totalRepayment}. </div> 
             <div className=" flex justify-center font-bold text-center">Your monthly repayment { ((totalRepayment) / months)} for the period of {months} month(s). </div> 
          </div>
            }
         
          </div>

          <button type="button" onClick={summary} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
        </form>



      </section >



      <footer id="footer" style={{ backgroundColor: '#0A0F18' }} className="py-16 px-8">
        <div className="container px-4">
          <div className="flex flex-wrap">
            <div className="w-full  md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">

                <div className='flex justify-center md:justify-start'>
                  <h2 className="text-3xl font-bold leading-snug text-[#3d4fca] mb-5 ">
                    <span className=' bg-opacity-70'>
                      <img src="images/logo2.png" className=" w-28 bg-white" alt="" />
                    </span>
                  </h2>
                </div>

                <p className="text-gray-300 mb-2 flex justify-center md:justify-start">
                  Copyright 2023 Skypay
                </p>

                <p className="text-gray-300 mb-5 flex justify-center md:justify-start">
                  All right reserved
                </p>

                <ul className="social-icons flex md:justify-start justify-center">
                  <li className="mr-4">
                    <a
                      href="javascript:void(0)"
                      className="
                    flex
                    justify-center
                    items-center
                    w-8
                    h-8
                    bg-white
                    rounded-full
                    text-sm text-gray-700
                    duration-300
                    hover:text-white hover:bg-blue-400
                  "
                    >
                      <FaFacebookF />
                    </a>
                  </li>
                  <li className="mr-4">
                    <a
                      href="javascript:void(0)"
                      className="
                    flex
                    justify-center
                    items-center
                    w-8
                    h-8
                    bg-white
                    rounded-full
                    text-sm text-gray-700
                    duration-300
                    hover:text-white hover:bg-red-500
                  "
                    >
                      <FaTwitter />
                    </a>
                  </li>
                  <li className="mr-4">
                    <a
                      href="javascript:void(0)"
                      className="
                    flex
                    justify-center
                    items-center
                    w-8
                    h-8
                    bg-white
                    rounded-full
                    text-sm text-gray-700
                    duration-300
                    hover:text-white hover:bg-indigo-600
                  "
                    >
                      <FaLinkedin />
                    </a>
                  </li>
                </ul>


              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">
                <h3 className="font-bold text-xl text-white mb-5">Menu</h3>
                <ul>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Features
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Services
                    </a>
                  </li>

                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      About us
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">
                <h3 className="font-bold text-xl text-white mb-5">Company</h3>
                <ul>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Management
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Our story
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Partnership
                    </a>
                  </li>
                </ul>
              </div>
            </div>


            <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">
                <h3 className="font-bold text-xl text-white mb-5">Support</h3>
                <ul>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Support Center
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Transaction
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Privacy Policy
                    </a>
                  </li>

                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>


            <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">
                <h3 className="font-bold text-xl text-white mb-5">Get notified by Skypay</h3>
                <form>
                  <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <SvgElement type={icontypesEnum.EMAIL} />
                    </div>
                    <input type="search" id="search" className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="EMAIL" required />
                    <button type="submit" className="text-white absolute right-2.5 bottom-2.5 bg-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2 "><SvgElement type={icontypesEnum.SEND} /></button>
                  </div>
                </form>


              </div>
            </div>
          </div>
        </div>
      </footer>
    </div >
  )
}
export default LoanCal;
