import { Fragment, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Navbar from './Navbar'
import { FaLinkedin, FaTwitter, FaFacebookF } from 'react-icons/fa';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { SvgElement, icontypesEnum } from "../asset/svgElement";

function PrivateSector() {

  const [open, setOpen] = useState(1);

  const handleOpen = (value: any) => {
    setOpen(open === value ? 0 : value);
  };


  return (
    <div>
      <Navbar />


      <section id="feature" style={{}} className="mt-4 pt-20 py-10 px-4 sm:mx-4">
        <div className="container px-1">
          <div className="flex flex-wrap items-stretch">
            <div className="w-full lg:w-2/3">
            <div className="md:mt-14 mt-10">
                    <button
                    style={{backgroundColor:'#2550A3'}}
                      className=" text-white px-10 py-3 duration-300 "
                      type="button"
                    >
                     Private Sector Loan
                    </button>
                  </div>

              <div className="mb-0">
                <h2 style={{ color: '#080705',lineHeight:'60px'}} className="md:mt-16 mt-10 text-5xl font-bold tracking-wide">
                Unlock Your Potential with an Individual Loan for Private Sector Employees
                </h2>
                <div className="mt-8">
                    <button
                      className=" text-white bg-blue-500 px-6 py-2 rounded-lg duration-300  hover:bg-blue-500"
                      type="button"
                    >
                     <NavLink
                    to="https://skypaycredit.evolvecredit.io/p/skypay-individual-loan-(private-sector)"
                    
                   
                  >
                    Apply Now
                    </NavLink>
                    </button>
                  </div>

              
              </div>
            </div>

            <div className="w-auto lg:w-1/3">
              <div className="mx-3 lg:mr-0 mt-20 lg:ml-10">
                <img src="images/privateSector.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="team" className="py-24 text-center px-3">
        <div className="container ">
          <div className="text-center">
            <h2 style={{ color: '#080705' }} className="text-4xl font-bold tracking-wide">
            How It Works
            </h2>

            <h2 style={{ color: '#080705' }} className="mt-4 tracking-wide mb-10">
            This loan is opened to employees of private companies such as banks, insurance companies and other<br/> financial institutions, telecommunication companies, oil and gas companies and other reputable private<br/> companies who have urgent financial needs and obligations to meet.
            </h2>

            <h2 style={{ color: '#080705' }} className="mt-4 tracking-wide mb-10">
            This loan can be disbursed within 24 hours of successful completion of loan application and<br/> other requirements.
            </h2>
          </div>

        
        </div>
      </section>

      <section className=" lg:ml-4 sm:mx-4">

      <h2 style={{ color: '#080705' }} className="text-3xl font-bold flex justify-center mb-10">
      Terms and Requirement
            </h2>

        <div className="grid lg:grid-cols-2 grid-cols-1 lg:mx-60 gap-2 mb-4 px-10 rounded-lg" >

          
            <span className="flex justify-items-start gap-2  mb-6" style={{ fontSize: '18px' }}>
              <span><SvgElement type={icontypesEnum.MARK} /></span>
              <span>Complete loan application form</span>
            </span>

            <span className="flex justify-items-start gap-2  mb-6" style={{ fontSize: '18px' }}>
              <span><SvgElement type={icontypesEnum.MARK} /></span>
              <span>Maximum 6 months tenure</span>
            </span>

            <span className="flex justify-items-start gap-2  mb-6" style={{ fontSize: '18px' }}>
              <span><SvgElement type={icontypesEnum.MARK} /></span>
              <span>Convenient Repayment</span>
            </span>

            <span className="flex justify-items-start gap-2  mb-6" style={{ fontSize: '18px' }}>
              <span><SvgElement type={icontypesEnum.MARK} /></span>
              <span>Proof of employment</span>
            </span>

            <span className="flex justify-items-start gap-2  mb-6" style={{ fontSize: '18px' }}>
              <span><SvgElement type={icontypesEnum.MARK} /></span>
              <span>Months statement of bank account</span>
            </span>

            <span className="flex justify-items-start gap-2  mb-6" style={{ fontSize: '18px' }}>
              <span><SvgElement type={icontypesEnum.MARK} /></span>
              <span>Direct debit instruction</span>
            </span>

            <span className="flex justify-items-start gap-2  mb-6" style={{ fontSize: '18px' }}>
              <span><SvgElement type={icontypesEnum.MARK} /></span>
              <span>5% Interest rate per month (flat rate)</span>
            </span>

            <span className="flex justify-items-start gap-2  mb-6" style={{ fontSize: '18px' }}>
              <span><SvgElement type={icontypesEnum.MARK} /></span>
              <span>1.5% insurance cover (one off for a specific loan)</span>
            </span>

            <span className="flex justify-items-start gap-2  mb-6" style={{ fontSize: '18px' }}>
              <span><SvgElement type={icontypesEnum.MARK} /></span>
              <span>1% admin charge (one off)</span>
            </span>

        </div>

    
      </section>



      <footer id="footer" style={{ backgroundColor: '#0A0F18' }} className="py-16 px-8">
        <div className="container px-4">
          <div className="flex flex-wrap">
            <div className="w-full  md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">

                <div className='flex justify-center md:justify-start'>
                  <h2 className="text-3xl font-bold leading-snug text-[#3d4fca] mb-5 ">
                    <span className=' bg-opacity-70'>
                      <img src="images/logo2.png" className=" w-28 bg-white" alt="" />
                    </span>
                  </h2>
                </div>

                <p className="text-gray-300 mb-2 flex justify-center md:justify-start">
                  Copyright 2023 Skypay
                </p>

                <p className="text-gray-300 mb-5 flex justify-center md:justify-start">
                  All right reserved
                </p>

                <ul className="social-icons flex md:justify-start justify-center">
                  <li className="mr-4">
                    <a
                      href="javascript:void(0)"
                      className="
                    flex
                    justify-center
                    items-center
                    w-8
                    h-8
                    bg-white
                    rounded-full
                    text-sm text-gray-700
                    duration-300
                    hover:text-white hover:bg-blue-400
                  "
                    >
                      <FaFacebookF />
                    </a>
                  </li>
                  <li className="mr-4">
                    <a
                      href="javascript:void(0)"
                      className="
                    flex
                    justify-center
                    items-center
                    w-8
                    h-8
                    bg-white
                    rounded-full
                    text-sm text-gray-700
                    duration-300
                    hover:text-white hover:bg-red-500
                  "
                    >
                      <FaTwitter />
                    </a>
                  </li>
                  <li className="mr-4">
                    <a
                      href="javascript:void(0)"
                      className="
                    flex
                    justify-center
                    items-center
                    w-8
                    h-8
                    bg-white
                    rounded-full
                    text-sm text-gray-700
                    duration-300
                    hover:text-white hover:bg-indigo-600
                  "
                    >
                      <FaLinkedin />
                    </a>
                  </li>
                </ul>


              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">
                <h3 className="font-bold text-xl text-white mb-5">Menu</h3>
                <ul>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Features
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Services
                    </a>
                  </li>

                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      About us
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">
                <h3 className="font-bold text-xl text-white mb-5">Company</h3>
                <ul>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Management
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Our story
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Partnership
                    </a>
                  </li>
                </ul>
              </div>
            </div>


            <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">
                <h3 className="font-bold text-xl text-white mb-5">Support</h3>
                <ul>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Support Center
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Transaction
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Privacy Policy
                    </a>
                  </li>

                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>


            <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">
                <h3 className="font-bold text-xl text-white mb-5">Get notified by Skypay</h3>
                <form>
                  <label  className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <SvgElement type={icontypesEnum.EMAIL} />
                    </div>
                    <input type="search" id="search" className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="EMAIL" required />
                      <button type="submit" className="text-white absolute right-2.5 bottom-2.5 bg-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2 "><SvgElement type={icontypesEnum.SEND} /></button>
                  </div>
                </form>


              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
export default PrivateSector;
