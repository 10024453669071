import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Hero from './pages/Hero'
import About from './pages/About'
import PrivateSector from './pages/PrivateSector'
import PublicSector from './pages/PublicSector'
import Sme from './pages/Sme'
import Contact from './pages/Contact'
import Payday from './pages/Payday'
import LoanCal from './pages/LoanCal';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Hero />} />
      <Route path="/about" element={<About />} />
      <Route path="/private-sector" element={<PrivateSector />} />
      <Route path="/public-sector" element={<PublicSector />} />
      <Route path="/smes" element={<Sme />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/payday-loan" element={<Payday />} />
      <Route path="/loan-calculator" element={<LoanCal />} />

    </Routes>
  );
}

export default App;
