import { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import Navbar from './Navbar'
import { FaLinkedin, FaTwitter, FaFacebookF } from 'react-icons/fa';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { SvgElement, icontypesEnum } from "../asset/svgElement";

function Home() {

  const [open, setOpen] = useState(1);

  const handleOpen = (value: any) => {
    setOpen(open === value ? 0 : value);
  };


  return (
    <div>
      <Navbar />
      <section id="feature" style={{ backgroundColor: '#EEF2FA' }} className="mt-4 pt-20 py-10 sm:mx-4">
        <div className="md:mx-10 mx-4">
          <div className="flex flex-wrap items-stretch">
            <div className="w-full lg:w-2/3">
              <div className="mb-0">
                <h2 style={{ color: '#080705', fontSize: '52px', lineHeight: '71px' }} className="mt-16 text-4xl font-bold tracking-wide">
                  Unlocking Your <br />
                   <span className="text-blue-500">Financial</span> Freedom.
                </h2>

                <div className="mt-10">
                  <span className="flex justify-items-start gap-2" style={{ fontSize: '18px' }}>
                    <span><SvgElement type={icontypesEnum.MARK} /></span>
                    <span>Accessing competitive interest rates and flexible loan terms.</span>
                  </span>

                  <span className="flex justify-items-start gap-2 mt-6" style={{ fontSize: '18px' }}>
                    <span><SvgElement type={icontypesEnum.MARK} /></span>
                    <span>Utilizing a secure, user-friendly online platform for loan applications.</span>
                  </span>

                  <span className="flex justify-items-start gap-2 mt-6" style={{ fontSize: '18px' }}>
                    <span><SvgElement type={icontypesEnum.MARK} /></span>
                    <span>Investing in yourself and your future with long-term financial security.</span>
                  </span>

                  <div className="mt-14">
                  <NavLink to="https://skypaycredit.evolvecredit.io/p/skypay-individual-loan-(private-sector)">
                    <button
                      className=" text-white bg-blue-500 px-10 py-3 rounded-lg duration-300  hover:bg-blue-500"
                      type="button"
                    >
                      Get Instant Loans
                    </button>
                    </NavLink>
                  </div>

                </div>
                {/*  */}
              </div>
            </div>

            <div className="w-full lg:w-1/3">
              <div className="mx-3 lg:mr-0 lg:ml-10">
                <img src="images/index.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="team" className="py-24 text-center md:mx-10">
        <div className="container ">
          <div className="text-center">
            <h2 style={{ color: '#080705' }} className="text-4xl font-bold tracking-wide">
              Unlock Financial Freedom with Our<br /> Feature-Rich Loan Solutions
            </h2>

            <h2 style={{ color: '#080705' }} className="mt-4 tracking-wide mb-10">
              Our goal is to ensure that customers are able to access the funds they need while also taking <br /> advantage of competitive interest rates and flexible repayment terms.
            </h2>
          </div>
          <div className="grid lg:grid-cols-4 mx-3 md:grid-cols-2 grid-cols-1 gap-4  lg:ml-4">
            <a href="#" className="block max-w-sm p-6 bg-gradient-to-b from-blue-100 to-blue-000 border-gray-200 rounded-lg shadow hover:bg-gray-100 ">
              <span className="flex justify-center"> <SvgElement type={icontypesEnum.RATE} /></span>
              <h5 className="mb-2 font-bold tracking-tight text-gray-900 mt-10" style={{ fontSize: '23px' }}>Low Interest Rate</h5>
              <p className=" text-gray-700" style={{ fontSize: '16px' }}>We offer competitive interest rates to help customers save money over the life of their loan.</p>
            </a>

            <a href="#" className="block max-w-sm p-6 bg-gradient-to-b from-blue-100 to-blue-000 border-gray-200 rounded-lg shadow hover:bg-gray-100 ">
              <span className="flex justify-center"> <SvgElement type={icontypesEnum.LOAN} /></span>
              <h5 className="mb-2 font-bold tracking-tight text-gray-900 mt-10" style={{ fontSize: '23px' }}>Fast Loan Approval</h5>
              <p className=" text-gray-700" style={{ fontSize: '16px' }}>We offer competitive interest rates to help customers save money over the life of their loan.</p>
            </a>


            <a href="#" className="block max-w-sm p-6 bg-gradient-to-b from-blue-100 to-blue-000 border-gray-200 rounded-lg shadow hover:bg-gray-100 ">
              <span className="flex justify-center"> <SvgElement type={icontypesEnum.SOLUTION} /></span>
              <h5 className="mb-2 font-bold tracking-tight text-gray-900 mt-10" style={{ fontSize: '23px' }}>Tailored Solutions</h5>
              <p className=" text-gray-700" style={{ fontSize: '16px' }}>We provide tailored loan solutions that are based on the customer’s budget and financial needs.</p>
            </a>

            <a href="#" className="block max-w-sm p-6 bg-gradient-to-b from-blue-100 to-blue-000 border-gray-200 rounded-lg shadow hover:bg-gray-100 ">
              <span className="flex justify-center"> <SvgElement type={icontypesEnum.SUPPORT} /></span>
              <h5 className="mb-2 font-bold tracking-tight text-gray-900 mt-10" style={{ fontSize: '23px' }}>Customer Support</h5>
              <p className=" text-gray-700" style={{ fontSize: '16px' }}>We offer 24/7 customer support and assistance to answer any questions or concerns that customers may have.</p>
            </a>

          </div>
        </div>
      </section>

      <section className="grid lg:grid-cols-2 grid-cols-1 gap-8 lg:ml-4 mx-1">

        <div className="mb-4 md:px-10 px-4 py-4 mx-2 rounded-lg" style={{ backgroundColor: "#EBF1FC" }}>
          <div style={{ fontSize: '46px' }} className="pt-18 pb-6 font-bold">Loans</div>

          <div style={{ color: '#393937' }} className="text-2xl ">
            We offer loans to individuals, corporations & SMEs to support business growth. Tailored to public & private sector employees and financial assistance to help companies succeed.
          </div>


          <button
            className="text-white bg-blue-500 px-5 py-2 mt-10 rounded-lg duration-300  hover:bg-blue-500"
            type="button"
          >
            FEATURES
          </button>


          <div className="mt-10">
            <span className="flex justify-items-start gap-2" style={{ fontSize: '18px' }}>
              <span><SvgElement type={icontypesEnum.MARK} /></span>
              <span>All loans are insured with reputable underwriters.</span>
            </span>

            <span className="flex justify-items-start gap-2 mt-6" style={{ fontSize: '18px' }}>
              <span><SvgElement type={icontypesEnum.MARK} /></span>
              <span>We offer a flexible repayment tenure of up to 12 months.</span>
            </span>


            <span className="flex justify-items-start gap-2 mt-6" style={{ fontSize: '18px' }}>
              <span><SvgElement type={icontypesEnum.MARK} /></span>
              <span>Get up to 5-million-naira loans at attractive rates for Individuals and SMEs</span>
            </span>

            <span className="flex justify-items-start gap-2 mt-6" style={{ fontSize: '18px' }}>
              <span><SvgElement type={icontypesEnum.MARK} /></span>
              <span>Easy processing of loans and quick disbursement within 6 hours upon submission of the right documentation.</span>
            </span>
          </div>
        </div>

        <div className="md:mx-20 mx-2 p-5 rounded-lg grid md:grid-cols-2  gap-8 md:mb-32" style={{ backgroundColor: "#F3F4F8" }}>
          <a href="#" className="block max-w-sm p-6 border-gray-200 rounded-lg shadow hover:bg-gray-100 " style={{ backgroundColor: '#FFFFFC' }}>
            <span className="flex justify-center"><SvgElement type={icontypesEnum.CASH2} /></span>
            <p className="mb-2 font-bold text-gray-900 mt-10 flex justify-center" style={{ fontSize: '23px' }}>Pay Day Loans</p>

          </a>

          <a href="#" className="block max-w-sm p-6 border-gray-200 rounded-lg shadow hover:bg-gray-100" style={{ backgroundColor: '#FFFFFC' }}>
            <span className="flex justify-center">  <SvgElement type={icontypesEnum.CASH2} /></span>
            <h5 className="mb-2 font-bold tracking-tight text-gray-900 mt-10 flex justify-center" style={{ fontSize: '23px' }}>Public Sector Loans</h5>

          </a>

          <a href="#" className="block max-w-sm p-6 border-gray-200 rounded-lg shadow hover:bg-gray-100 " style={{ backgroundColor: '#FFFFFC' }}>
            <span className="flex justify-center"> <SvgElement type={icontypesEnum.CASH2} /></span>
            <h5 className="mb-2 font-bold tracking-tight text-gray-900 mt-10 flex justify-center" style={{ fontSize: '23px' }}>Private Sector Loans</h5>

          </a>

          <a href="#" className="block max-w-sm p-6 border-gray-200 rounded-lg shadow hover:bg-gray-100 " style={{ backgroundColor: '#FFFFFC' }}>
            <span className="flex justify-center"><SvgElement type={icontypesEnum.CASH2} /></span>
            <h5 className="mb-2 font-bold tracking-tight text-gray-900 mt-10 flex justify-center" style={{ fontSize: '23px' }}>SMEs Loans</h5>
          </a>
        </div>
      </section>








      <section className="grid lg:grid-cols-2 grid-cols-1 gap-8 lg:ml-4 md:mt-32 mt-10 mb-32">

        <div className="mx-10">
          <p className="font-bold md:text-5xl text-3xl ">Payment Services</p>

          <p className="font-bold md:mt-11 mt-4 text-xl">Streamline Your Payment Process with Our Secure Payment Services</p>

          <p style={{}} className="mt-4">Our Payment Services provide customers with a secure and convenient way to manage their loan payments. Our online platform offers a range of payment options, including automatic payments, online payment portals, and more.</p>

          <button
            className="mb-4 text-white bg-blue-500 px-5 py-2 mt-10 rounded-lg duration-300  hover:bg-blue-500"
            type="button"
          >
            Get Started
          </button>
        </div>

        <div className="lg:ml-32 mb-10 mx-4">
          <span style={{ backgroundColor: '#EBF1FC', fontSize: '18px' }} className="flex justify-items-start gap-4 mt-6 lg:mx-10 px-10 py-4 rounded-lg">
            <span><SvgElement type={icontypesEnum.HOUSE} /></span>
            <span>Easy Inter Bank Transfer.</span>
          </span>

          <span style={{ backgroundColor: '#EBF1FC', fontSize: '18px' }} className="flex justify-items-start gap-4 mt-6 lg:mx-10 px-10 py-4 rounded-lg">
            <span><SvgElement type={icontypesEnum.PROFILE} /></span>
            <span>Transfer Funds Seamlessly to Friends, Family & Business Partners.</span>
          </span>

          <span style={{ backgroundColor: '#EBF1FC', fontSize: '18px' }} className="flex justify-items-start gap-4 mt-6 lg:mx-10 px-10 py-4 rounded-lg">
            <span><SvgElement type={icontypesEnum.PHONE} /></span>
            <span>Top Up Your Phone with Airtime & Data.</span>
          </span>

          <span style={{ backgroundColor: '#EBF1FC', fontSize: '18px' }} className="flex justify-items-start gap-4 mt-6 lg:mx-10 px-10 py-4 rounded-lg">
            <span><SvgElement type={icontypesEnum.TV} /></span>
            <span>Sort Out Your Utility and Cable Bills.</span>
          </span>

        </div>

      </section>



      <section className="mb-20 mx-4">
        <span className="flex justify-center text-2xl md:text-5xl font-bold">Frequently Asked Questions</span>
        <span className="flex justify-center mt-6 mb-16" style={{ fontSize: '18px' }}>Our FAQs are organized into easy-to-navigate categories, so customers can quickly find the answers they need.<br />
          We strive to provide comprehensive and accurate information to help customers make informed decisions about their loan.</span>





        <div className="lg:mx-60 mx-4">
          <Fragment>
            <Accordion open={open === 1}>
              <AccordionHeader onClick={() => handleOpen(1)}>
                What types of loans do you offer?
              </AccordionHeader>
              <AccordionBody>
                We offer a wide range of loan types, including personal loans, business loans, home loans, auto loans, and more
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 2}>
              <AccordionHeader onClick={() => handleOpen(2)}>
                What types of loans do you offer?
              </AccordionHeader>
              <AccordionBody>

              </AccordionBody>
            </Accordion>
            {/* <Accordion open={open === 3}>
              <AccordionHeader onClick={() => handleOpen(3)}>
                What can I do with Material Tailwind?
              </AccordionHeader>
              <AccordionBody>
                We&apos;re not always in the position that we want to be at.
                We&apos;re constantly growing. We&apos;re constantly making mistakes.
                We&apos;re constantly trying to express ourselves and actualize our
                dreams.
              </AccordionBody>
            </Accordion> */}
          </Fragment>
        </div>

      </section>






      <footer id="footer" style={{ backgroundColor: '#0A0F18' }} className="py-16 px-8">
        <div className="container px-4">
          <div className="flex flex-wrap">
            <div className="w-full  md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">

                <div className='flex justify-center md:justify-start'>
                  <h2 className="text-3xl font-bold leading-snug text-[#3d4fca] mb-5 ">
                    <span className=' bg-opacity-70'>
                      <img src="images/logo2.png" className=" w-28 bg-white" alt="" />
                    </span>
                  </h2>
                </div>

                <p className="text-gray-300 mb-2 flex justify-center md:justify-start">
                  Copyright 2023 Skypay
                </p>

                <p className="text-gray-300 mb-5 flex justify-center md:justify-start">
                  All right reserved
                </p>

                <ul className="social-icons flex md:justify-start justify-center">
                  <li className="mr-4">
                    <a
                      href="javascript:void(0)"
                      className="
                    flex
                    justify-center
                    items-center
                    w-8
                    h-8
                    bg-white
                    rounded-full
                    text-sm text-gray-700
                    duration-300
                    hover:text-white hover:bg-blue-400
                  "
                    >
                      <FaFacebookF />
                    </a>
                  </li>
                  <li className="mr-4">
                    <a
                      href="javascript:void(0)"
                      className="
                    flex
                    justify-center
                    items-center
                    w-8
                    h-8
                    bg-white
                    rounded-full
                    text-sm text-gray-700
                    duration-300
                    hover:text-white hover:bg-red-500
                  "
                    >
                      <FaTwitter />
                    </a>
                  </li>
                  <li className="mr-4">
                    <a
                      href="javascript:void(0)"
                      className="
                    flex
                    justify-center
                    items-center
                    w-8
                    h-8
                    bg-white
                    rounded-full
                    text-sm text-gray-700
                    duration-300
                    hover:text-white hover:bg-indigo-600
                  "
                    >
                      <FaLinkedin />
                    </a>
                  </li>
                </ul>


              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">
                <h3 className="font-bold text-xl text-white mb-5">Menu</h3>
                <ul>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Features
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Services
                    </a>
                  </li>

                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      About us
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">
                <h3 className="font-bold text-xl text-white mb-5">Company</h3>
                <ul>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Management
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Our story
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Partnership
                    </a>
                  </li>
                </ul>
              </div>
            </div>


            <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">
                <h3 className="font-bold text-xl text-white mb-5">Support</h3>
                <ul>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Support Center
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Transaction
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Privacy Policy
                    </a>
                  </li>

                  <li>
                    <a
                      href="javascript:void(0)" className="leading-9 text-gray-100 duration-300 hover:text-blue-500"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>


            <div className="w-full md:w-1/2 lg:w-1/5 flex justify-center md:justify-start">
              <div className="mx-3 mb-8">
                <h3 className="font-bold text-xl text-white mb-5">Get notified by Skypay</h3>
                <form>
                  <label  className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <SvgElement type={icontypesEnum.EMAIL} />
                    </div>
                    <input type="search" id="search" className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="EMAIL" required />
                      <button type="submit" className="text-white absolute right-2.5 bottom-2.5 bg-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2 "><SvgElement type={icontypesEnum.SEND} /></button>
                  </div>
                </form>


              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
export default Home;
